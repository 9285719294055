<!--<style>-->
<!--    .hero-section {-->
<!--        @apply bg-cover bg-center bg-no-repeat  min-h-screen flex items-center justify-center ;-->
<!--        /* Replace the URL with the URL of your chosen stock photo */-->

<!--        background-image: url('https://cdn.discordapp.com/attachments/989268410171006976/1087128879312945275/theprancingtiger_a_simple_photo_of_a_silhouette_of_a_detective__131991ad-e0e8-487f-8dae-dcb14591619a.png');-->
<!--        height: 280px;-->
<!--        width: 100%;-->
<!--        background-repeat: no-repeat;-->
<!--        background-position: center;-->
<!--        background-size: 100% auto;-->

<!--    }-->
<!--    .hero-title {-->
<!--        @apply text-7xl font-extrabold  mb-4;-->
<!--        color: #2BA4A0;-->
<!--        font-size: 80px;-->

<!--    }-->

<!--</style>-->

<!--<section class="hero-section">-->
<!--    <div class="container mx-auto px-4">-->
<!--        <div class="text-center">-->
<!--            <h1 class="hero-title font-roboto text-green-50"style="font-family: 'Poppins', sans-serif;">Arcane Investigations</h1>-->

<!--        </div>-->
<!--    </div>-->
<!--</section>-->
<style>
    .hero-section {
        @apply bg-cover bg-center bg-no-repeat  min-h-screen flex items-center justify-center;
        background-image: url('https://cdn.discordapp.com/attachments/989268410171006976/1087128879312945275/theprancingtiger_a_simple_photo_of_a_silhouette_of_a_detective__131991ad-e0e8-487f-8dae-dcb14591619a.png');
        height: 280px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        color: #2BA4A0;
    }
</style>

<section class="hero-section">
    <div class="container mx-auto px-4">
        <div class="text-center">
            <h1 class="text-4xl sm:text-5xl md:text-7xl font-extrabold mb-4 font-roboto" style="font-family: 'Poppins', sans-serif;">Arcane Investigations</h1>
        </div>
    </div>
</section>