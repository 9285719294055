<!--<style>-->
<!--    .about-title {-->
<!--        @apply text-3xl font-semibold mb-4;-->
<!--    }-->
<!--    .about-text {-->
<!--        @apply text-white text-lg mb-4;-->
<!--    }-->
<!--</style>-->

<!--<section id="about-us" class="container mx-auto px-4 py-12 bg-white ">-->
<!--    <div class="text-center">-->
<!--        <h2 class="about-title">About Arcane Investigations</h2>-->
<!--    </div>-->
<!--    <div class="flex flex-wrap justify-center">-->
<!--        <div class="w-full md:w-1/2 lg:w-1/3 px-4">-->
<!--            <h3 class="text-2xl font-semibold mb-2">Who We Are</h3>-->
<!--            <p class="about-text">-->
<!--                Arcane Investigations is a professional private investigation firm based in Philadelphia. We specialize in providing discreet, high-quality services to clients in the Philadelphia, New Jersey, and Delaware areas. Our team of experienced investigators is dedicated to uncovering the truth and helping our clients get the answers they need.-->
<!--            </p>-->
<!--        </div>-->
<!--        <div class="w-full md:w-1/2 lg:w-1/3 px-4">-->
<!--            <h3 class="text-2xl font-semibold mb-2">Our Services</h3>-->
<!--            <p class="about-text">-->
<!--                We offer a wide range of investigative services, including surveillance, background checks, infidelity investigations, fraud investigations, asset searches, and more. Our thorough and methodical approach ensures that we leave no stone unturned in our pursuit of the truth.-->
<!--            </p>-->
<!--        </div>-->
<!--        <div class="w-full md:w-1/2 lg:w-1/3 px-4">-->
<!--            <h3 class="text-2xl font-semibold mb-2">Why Choose Us</h3>-->
<!--            <p class="about-text">-->
<!--                At Arcane Investigations, we pride ourselves on our commitment to client satisfaction. We understand that each case is unique, which is why we work closely with our clients to develop a customized strategy that will meet their specific needs. With our extensive experience and unparalleled attention to detail, you can trust Arcane Investigations to deliver results.-->
<!--            </p>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->
<style>
    .about-title {
        @apply text-3xl font-semibold mb-4;
    }
    .about-text {
        @apply text-black text-lg mb-4;
        color: #FFF6EB

    }
    .section-container {
        @apply bg-white py-12;
    }
    .title{
        color: #2BA4A0;
        font-weight: bolder;
    }
    #about-us{
       @apply bg-gray-500;
        background-color: rgba(41, 41, 41, 0.9);
    }
    #who{
        color: #b75b00;
    }
    #Our{
        color: #b75b00;
    }
    #Why{
        color: #b75b00;
    }
</style>

<section id="about-us" class="container mx-auto px-4">
    <div class=" title text-center text-3xl p-5 ">
        <h2 class="about-title"style="font-family: 'Poppins', sans-serif;">Investigative Services & Consulting</h2>
    </div>
    <div class="section-container p-5">
        <div class="w-full px-4">
            <h3 id="who" class="text-2xl font-bold mb-2"style="font-family: 'Poppins', sans-serif;">Who We Are</h3>
            <p class="about-text">
                Arcane Investigations is a professional private investigation firm based in Philadelphia. We specialize in providing discreet, high-quality services to clients in the Philadelphia, New Jersey, and Delaware areas. Our team of experienced investigators is dedicated to uncovering the truth and helping our clients get the answers they need.
            </p>
        </div>
    </div>
    <div class="section-container p-5">
        <div class="w-full px-4">
            <h3 id="Our" class="text-2xl font-bold mb-2"style="font-family: 'Poppins', sans-serif;">Our Services</h3>
            <p class="about-text">
                We offer a wide range of investigative services, including surveillance, background checks, infidelity investigations, fraud investigations, asset searches, and more. Our thorough and methodical approach ensures that we leave no stone unturned in our pursuit of the truth.
            </p>
        </div>
    </div>
    <div class="section-container p-5">
        <div class="w-full px-4">
            <h3 id="Why" class="text-2xl font-bold mb-2"style="font-family: 'Poppins', sans-serif;">Why Choose Us</h3>
            <p class="about-text">
                At Arcane Investigations, we pride ourselves on our commitment to client satisfaction. We understand that each case is unique, which is why we work closely with our clients to develop a customized strategy that will meet their specific needs. With our extensive experience and unparalleled attention to detail, you can trust Arcane Investigations to deliver results.
            </p>
        </div>
    </div>
</section>